@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'Raleway', sans-serif;
  background-color: #fcfcfc;
}

.loading-text {
  color: #000000;
  font-weight: bold;
  font-size: 2rem;
  margin-left: 45%;
  margin-right: auto;
  margin-top: 20%;
}

.slide-pane__overlay.ReactModal__Overlay--after-open {
  z-index: 50;
}

.logo-image {
  width: 20rem;
}
.weekDays-selector input {
  display: none !important;
}

.weekDays-selector input[type='checkbox'] + label {
  display: inline-block;
  border-radius: 6px;
  background: #dddddd;
  height: 40px;
  width: 50px;
  margin-right: 3px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.weekDays-selector input[type='checkbox']:checked + label {
  background: #336699;
  color: #ffffff;
}

.grossProfit-selector input {
  display: none !important;
}

.grossProfit-selector input[type='checkbox'] + label {
  display: inline-block;
  border-radius: 6px;
  background: #dddddd;
  height: 40px;
  width: 50px;
  margin-right: 3px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.grossProfit-selector input[type='checkbox']:checked + label {
  background: #28a745;
  color: #ffffff;
}

.breakeven-selector input {
  display: none !important;
}

.breakeven-selector input[type='checkbox'] + label {
  display: inline-block;
  border-radius: 6px;
  background: #dddddd;
  height: 40px;
  width: 50px;
  margin-right: 3px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.breakeven-selector input[type='checkbox']:checked + label {
  background: #ffc107;
  color: #000000;
}

.targetGrossProfit-selector input {
  display: none !important;
}

.targetGrossProfit-selector input[type='checkbox'] + label {
  display: inline-block;
  border-radius: 6px;
  background: #dddddd;
  height: 40px;
  width: 50px;
  margin-right: 3px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.targetGrossProfit-selector input[type='checkbox']:checked + label {
  background: #17a2b8;
  color: #ffffff;
}

.wide-toggle-selector input {
  display: none !important;
}

.wide-toggle-selector input[type='checkbox'] + label {
  display: inline-block;
  border-radius: 6px;
  background: #dddddd;
  height: 40px;
  width: 250px;
  margin-right: 3px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.wide-toggle-selector input[type='checkbox']:checked + label {
  background: #336699;
  color: #0fffff;
}

.business-form-additional-details {
  padding-top: 50px;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout,
.pro-sidebar .pro-menu {
  background-color: #336699;
  color: #f0f8ff;
}

.pro-sidebar .pro-menu li:hover {
  background-color: #3d72a7;
  color: #f0f8ff;
}

.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: #2a547e;
  color: #f0f8ff;
}

.sidebar-header-arrow {
  cursor: pointer;
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  background-color: #3d72a7;
  color: #f0f8ff;
  margin-left: -15px;
  padding-top: -15px;
  margin-top: -15px;
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item {
  background-color: #3d72a7;
  color: #f0f8ff;
  margin-left: 15px;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  background-color: #3d72a7;
  color: #f0f8ff;
  padding-left: 55px;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item:before {
  display: none;
}

.spinner-border {
  width: 100px;
  height: 100px;
}

.loading-indicator {
  margin-left: 45%;
  margin-right: 45%;
  margin-top: 5%;
}

.footer-version {
  font-size: 0.8rem;
}

.header {
  background-color: #336699;
  min-width: 760px;
}

.profile-item {
  width: 170px !important;
}

.rangeslider {
  margin-top: -0.01rem;
  margin-bottom: 2.5rem;
}

.home-background {
  height: 700px;
  background-color: #eff7ff;
  /* background-image: url(./images/SBELogo300dpi.jpg);*/
  background-size: 100%;
  background-repeat: no-repeat;
}

.global-background {
  height: 100%;
  background-color: #eff7ff;
}

.chat-area,
.profile-form-section {
  padding-top: 70px;
}

.chat-area {
  padding: 20px;
  height: 400px;
  overflow-y: scroll;
}

.chat-area .chat-bubble {
  background-color: #bbdefb;
  padding: 20px;
  border-radius: 4px;
  max-width: 300px;
  word-wrap: break-word;
}

.no-borders {
  border-top: 0px;
}

.chat-bubble .chat-time {
  font-size: 12px;
  color: #757575;
  text-align: right;
}

.chat-area .chat-bubble.current-user {
  margin-left: auto;
  background-color: #a5d6a7;
}

.form-control {
  width: 90%;
}

.form-control:focus {
  box-shadow: none;
}

.btn-sml {
  padding-top: 0.3vh;
  font-size: 0.75rem;

  height: auto;
  overflow: none;
  max-height: 5vh;
}

.business-btn {
  margin-top: 1.5rem;
  padding: 8px;
  font-size: 2rem;
  overflow: hidden;
  min-width: 15rem;
  max-width: 15rem;
  min-height: 5em;
  max-height: 5rem;
}

.existing-business-btn {
  background-color: #a5d6a7;
}

.btn-submit {
  background-color: #2e7d32;
  color: white;
}

@media screen and (max-width: 767px) {
  .form-control {
    width: 100%;
  }

  .chat-area .chat-bubble {
    max-width: 250px;
  }
}

.contact-details-sliding-pane {
  margin-top: 80px;
}

.form_label {
  font-size: small;
}

.chat-section {
  max-width: 70%;
}

.profiles-section {
  max-width: 20%;
  text-align: right;
  background-color: #a7d6fd;
}

/* radio buttons to switch buttons  */

.switch-field {
  display: flex;
  margin-bottom: 36px;
  overflow: hidden;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: #e4e4e4;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 8px 16px;
  margin-right: 7px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #a5dc86;
  box-shadow: none;
}

.switch-field label:first-of-type {
  border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
  border-radius: 0 4px 4px 0;
}

.auto-table-layout {
  table-layout: auto !important;
  width: 100%;
}
